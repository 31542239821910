{
  "private": true,
  "name": "binx-web",
  "version": "1.5.2-canary.1",
  "source": "src/index.html",
  "scripts": {
    "dev": "parcel --port 3000",
    "build": "parcel build --no-cache",
    "type-check": "tsc --noEmit",
    "sast-check": "bearer scan . --config-file ./internals/bearer/config.yml",
    "lint": "eslint --ignore-path .gitignore",
    "test": "jest --watch",
    "test:ci": "jest --ci --coverage",
    "messages": "npm run messages:extract && npm run messages:compile",
    "messages:extract": "formatjs extract \"src/**/!(*.d).{ts,tsx}\" --out-file src/locales/temp.json",
    "messages:compile": "formatjs compile \"src/locales/temp.json\" --out-file src/locales/hu.json",
    "release:app": "env-cmd --file ./.env.local semantic-release",
    "release:messages": "env-cmd --file ./.env.local node ./internals/cmsIntegration/postNewVersion.js"
  },
  "engines": {
    "node": ">=20.9.0",
    "npm": ">=10.0.0"
  },
  "@parcel/resolver-default": {
    "packageExports": true
  },
  "dependencies": {
    "@azure/communication-chat": "1.4.0",
    "@azure/communication-common": "2.3.1",
    "@azure/communication-react": "1.13.0",
    "@binx/channel-backend-api": "2.13.0",
    "@binx/icons": "0.24.1",
    "@binx/tailwind-config": "0.2.0",
    "@binx/ui": "23.0.1",
    "@dotlottie/player-component": "2.7.11",
    "@fontsource/montserrat": "5.0.17",
    "@hookform/resolvers": "3.3.4",
    "@mantine/hooks": "7.8.0",
    "@react-aria/button": "3.9.3",
    "@react-aria/dialog": "3.5.12",
    "@react-aria/i18n": "3.10.2",
    "@react-aria/overlays": "3.21.1",
    "@react-aria/utils": "3.23.2",
    "@react-stately/datepicker": "3.9.2",
    "@react-stately/overlays": "3.6.5",
    "@sentry/react": "7.106.1",
    "@tanstack/react-query": "5.28.0",
    "@tanstack/react-query-devtools": "5.28.0",
    "axios": "1.6.7",
    "class-variance-authority": "0.7.0",
    "crypto-js": "4.2.0",
    "date-fns": "2.30.0",
    "dompurify": "3.1.5",
    "framer-motion": "10.16.5",
    "iban": "0.0.14",
    "json-keys-sort": "2.1.0",
    "jwt-decode": "4.0.0",
    "papaparse": "5.4.1",
    "pdf-lib": "1.17.1",
    "react": "18.2.0",
    "react-dom": "18.2.0",
    "react-dropzone": "14.2.3",
    "react-helmet": "6.1.0",
    "react-hook-form": "7.51.0",
    "react-idle-timer": "5.7.2",
    "react-intersection-observer": "9.8.1",
    "react-intl": "6.6.2",
    "react-pdf": "7.7.3",
    "react-qr-code": "2.0.15",
    "react-router-dom": "6.24.0",
    "swiper": "11.1.4",
    "tailwind-merge": "2.3.0",
    "yup": "1.4.0",
    "zustand": "4.5.4"
  },
  "devDependencies": {
    "@binx/eslint-config": "0.17.0",
    "@faker-js/faker": "8.4.1",
    "@formatjs/cli": "6.2.7",
    "@parcel/packager-raw-url": "2.12.0",
    "@parcel/watcher": "2.4.1",
    "@semantic-release/changelog": "6.0.3",
    "@semantic-release/git": "10.0.1",
    "@semantic-release/gitlab": "13.0.3",
    "@sentry/cli": "2.30.2",
    "@swc/jest": "0.2.36",
    "@testing-library/jest-dom": "6.4.2",
    "@testing-library/react": "14.2.1",
    "@testing-library/user-event": "14.5.2",
    "@types/crypto-js": "4.2.2",
    "@types/dompurify": "3.0.5",
    "@types/iban": "0.0.35",
    "@types/jest": "29.5.12",
    "@types/node": "20.11.26",
    "@types/papaparse": "5.3.14",
    "@types/react": "18.2.65",
    "@types/react-dom": "18.2.22",
    "@types/react-helmet": "6.1.11",
    "@types/react-input-mask": "3.0.5",
    "assert": "2.1.0",
    "autoprefixer": "10.4.18",
    "buffer": "6.0.3",
    "crypto-browserify": "3.12.0",
    "env-cmd": "10.1.0",
    "eslint": "8.57.0",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "lightningcss": "1.28.1",
    "parcel": "2.12.0",
    "postcss": "8.4.35",
    "prettier": "3.2.5",
    "prettier-plugin-tailwindcss": "0.5.12",
    "process": "0.11.10",
    "rimraf": "5.0.5",
    "semantic-release": "23.0.2",
    "stream-browserify": "3.0.0",
    "string_decoder": "1.3.0",
    "tailwindcss": "3.4.4",
    "typescript": "5.2.2",
    "vm-browserify": "1.1.2"
  }
}
